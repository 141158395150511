<template>
  <div>
    <div id="myEcharts" ref="chart"></div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
    }
  },
  created () {
    setTimeout(() => {
      this.drawEcharts()
    }, 300)
  },
  methods: {
    async drawEcharts () {
      const arr = [{
        name: '峡江县',
        value: 0,
        unit: 0
      }, {
        name: '永新县',
        value: 0,
        unit: 0
      }, {
        name: '永丰县',
        value: 0,
        unit: 0
      }, {
        name: '井冈山市',
        value: 0,
        unit: 0
      }, {
        name: '新干县',
        value: 0,
        unit: 0
      }]
      for (let i = 0; i < this.data.length; i++) {
        arr.push({
          name: this.data[i].areaName,
          value: this.data[i].equipmentCount,
          unit: this.data[i].unitCount
        })
      }
      const dom = this.$refs.chart
      const myChart = this.$echarts.init(dom)
      const jian = require('../../assets/js/jian.json')
      this.$echarts.registerMap('js', jian)
      const option = {
        visualMap: {
          min: 800,
          max: 30000,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['skyblue', 'aqua', 'blue', 'navy']
          },
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>设备数量：{c}<br/>'
        },
        series: [
          // 地图
          {
            type: 'map',
            mapType: 'js',
            zoom: 1.1, // 默认显示级别
            data: arr,
            label: {
              show: true,
              color: '#ffffff',
              emphasis: {
                color: 'white',
                show: false
              }
            },
            itemStyle: {
              normal: {
                borderColor: '#2980b9',
                borderWidth: 1,
                areaColor: '#12235c'
              },
              emphasis: {
                areaColor: '#808080',
                borderWidth: 0,
                color: 'green'
              }
            }
          }
        ]
      }
      this.$nextTick(() => {
        // 绘制图表
        myChart.setOption(option)
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
  padding: 10px;
}
</style>
