<template>
  <div>
    <div id="myEcharts" ref="chart"></div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
    }
  },
  created () {
    setTimeout(() => {
      this.drawEcharts()
    }, 300)
  },
  methods: {
    drawEcharts () {
      const month = []
      const isSolve = []
      const notSolve = []
      const total = []
      this.data.forEach((item, i) => {
        month.push(`${i + 1}月`)
        isSolve.push(item.isSolve)
        notSolve.push(item.notSolve)
        total.push(item.total)
      })
      console.log(isSolve)
      console.log(notSolve)
      console.log(total)
      // 基于准备好的dom，初始化echarts实例
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          data: ['已处理', '未处理'],
          textStyle: {
            color: '#01a4f7'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: month,
          axisLabel: {
            textStyle: {
              color: '#01a4f7'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#01a4f7'
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#01a4f7'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#01a4f7'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#01a4f7'
            }
          }
        },
        series: [
          {
            name: '已处理',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            color: '#91CC75',
            data: isSolve
          },
          {
            name: '未处理',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            color: '#FAC858',
            data: notSolve
          }
        ]
      }
      this.$nextTick(() => {
        const dome = this.$refs.chart
        const myChart = this.$echarts.init(dome)
        // 指定饼图中显示的数据格式
        // 绘制图表
        myChart.setOption(option)
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
}
</style>
