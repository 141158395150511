<template>
  <div class="chart-container">
    <div class="chart-col">
      <div class="chart-col">
        <div class="chart-row">
          <div class="chart">
            <div class="content_box">
              <div class="title">设备分类</div>
              <div class="content">
                <device-chart id="pipeChart1" :data="deviceList" v-if="deviceList.length > 0"></device-chart>
              </div>
            </div>
          </div>
          <div class="chart">
            <div class="content_box">
              <div class="title">告警统计</div>
              <div class="content">
                <alarm-chart id="pipeChart1" :data="alarmList" v-if="alarmList.length > 0"></alarm-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-col">
        <div class="chart">
          <div class="content_box">
            <div class="title">设备分类数量</div>
            <div class="content">
              <trend-chart id="pipeChart1" :data="deviceList" v-if="deviceList.length > 0">
              </trend-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-row">
      <div class="chart">
        <div class="content_box">
          <div class="title">地区设备数量统计</div>
          <div class="content" style="padding:10px">
            <map-chart id="pipeChart1" style="left:15px;top:0px" :data="countList" v-if="countList.length > 0"></map-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deviceChart from '../../components/chart/deviceChart.vue'
import alarmChart from '../../components/chart/alarmChart.vue'
import mapChart from '../../components/chart/mapChart.vue'
import trendChart from '../../components/chart/trendChart.vue'
export default {
  components: {
    deviceChart,
    alarmChart,
    mapChart,
    trendChart
  },
  data () {
    return {
      deviceList: [],
      alarmList: [],
      countList: [],
      areaCode: '',
      pptShow: false
    }
  },
  created () {
    this.areaCode = sessionStorage.getItem('areaCode')
    this.getDevice()
    this.getAlarm()
    this.getDeviceCount()
  },
  methods: {
    async getDevice () {
      const { data: result } = await this.$axios.get('/equipment/countByDeviceType', { params: { areaCode: this.areaCode } })
      if (result.code === 200) {
        this.deviceList = result.data
      }
    },
    async getAlarm () {
      this.alarmList = [
        {
          isSolve: 260, // 已接警
          notSolve: 15, // 已出警
          total: 150 // 告警中
        },
        {
          isSolve: 201,
          notSolve: 9,
          total: 250
        },
        {
          isSolve: 126,
          notSolve: 12,
          total: 140
        },
        {
          isSolve: 245,
          notSolve: 22,
          total: 350
        },
        {
          isSolve: 143,
          notSolve: 7,
          total: 170
        },
        {
          isSolve: 79,
          notSolve: 2,
          total: 170
        }
      ]
    },
    async getDeviceCount () {
      const areaCode = sessionStorage.getItem('areaCode')
      const { data: result } = await this.$axios.get('/equipment/countByArea', { params: { areaCode: areaCode } })
      if (result.code === 200) {
        this.countList = result.data
      }
    }
  }
}
</script>

<style lang="scss" scope>
.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  .chart-row {
    display: flex;
    flex: 1;
  }
  .chart-col {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .chart-col,
  .chart-row {
    .chart {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      .content_box {
        flex: 3;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        background: url("../../assets/img/main-box1.png") no-repeat center
          center;
        background-size: 100% 100%;
        .title {
          text-align: left;
          color: #3debff;
          font-weight: bold;
          font-size: 14px;
          border-left: 4px solid #3debff;
          padding-left: 5px;
          margin-left: 5%;
          box-sizing: border-box;
        }
        .content {
          height: 100%;
          #pipeChart1 {
            position: absolute;
            top: 15px;
            bottom: 0;
            right: 0;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
